import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, SnackbarContent ,Grid} from "@mui/material";
import { Fragment, useState, useCallback  } from "react";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { selectDiseaseCategory } from "../store/slices/diseaseCategorySlice";
type SnackbarType = 'success' | 'error';

function TableDownloadButton ({setOpenDialog, selectedRows, curation}: any) {
    //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    //const open = Boolean(anchorEl);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [snackbar, setSnackbar] = useState<{ open: boolean, message: string, type: SnackbarType }>({ open: false, message: "", type: 'success' });

    const handleDownloadSubmit = async () => {

        let hasError = false;
        if (!username) {
            setUsernameError("Please enter your username.");
            hasError = true;
        } else {
            setUsernameError(null);
        }
        if (!email) {
            setEmailError("Please enter your email address.");
            hasError = true;
        } else if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            hasError = true;
        } else {
            setEmailError(null);
        }

        if (hasError) {
            return;
        }

        try {
            setOpenDownloadDialog(false);
            let query = `/api/local_document_download?DiseaseCategory=${encodeURIComponent(diseaseCategory)}&To_Name=${encodeURIComponent(username)}&To_Email_Address=${encodeURIComponent(email)}&`;
            if (curation === "sample") {
                const rows: any = Array.from(new Set(Object.values(selectedRows)));
                rows.forEach((GEOAccession: string) => {
                    query += `GEOAccession=${GEOAccession}&`;
                });
            } else {
                selectedRows.forEach((GEOAccession: string) => {
                    query += `GEOAccession=${GEOAccession}&`;
                });
            }
            query = query.slice(0, -1);

            const response = await axiosConfig.get(query, { responseType: 'blob' });

            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "curation.zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

                setSnackbar({ open: true, message: 'Download successful!.', type: 'success' });
            } else {
                setSnackbar({ open: true, message: 'Download failed. Please try again.', type: 'error' });
            }
        } catch (error) {
            handleDownloadError(error);
            setSnackbar({ open: true, message: 'An error occurred. Please try again later.', type: 'error' });
        } finally {
            setUsername("");
            setEmail("");
        }
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleDownloadError = async (error: any) => {
        try {
            const errdata = JSON.parse(await new Blob([error.response.data]).text());
            if (errdata.msg === "Token has been revoked") {
                handleLogout(dispatch, navigate);
            } else if (errdata.msg === "Token has expired") {
                const response = await handleRefresh(loggedUser.refreshToken);
                refreshAccessToken(dispatch, loggedUser, response);
                document.getElementById(String("download_xlsx"))?.click();
            }
        } catch {
            if (error.response?.data?.msg === "Token has expired") {
                handleLogout(dispatch, navigate);
            }
        }
    };

    const handleCloseSnackbar = useCallback(() => {
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    return (
        <Fragment>
            <Button id="download_xlsx" size="medium" variant="outlined" style={{ backgroundColor: '#0C73EB', color: 'white', margin: '5px', textTransform: 'none', height: '30px', padding: '15px' }}onClick={() => {
             if ((curation === 'sample' && Object.keys(selectedRows).length === 0) || selectedRows.length === 0) {
               setOpenDialog(true); 
            } else {
                setOpenDownloadDialog(true); 
             }
          }}>
            <b>Download</b>
            </Button>
             {/*<Menu id="downloadmenu" anchorEl={anchorEl} open={open} onClose={() => { setAnchorEl(null); }}>
                <MenuItem id="download_xlsx"  onClick={() => { setAnchorEl(null); handleDownload(); }}>download as xlsx</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); }}>download as csv</MenuItem>
            </Menu>*/}

            <Dialog open={openDownloadDialog} onClose={() => setOpenDownloadDialog(false)}>
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: 'black' }}>Download File</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField autoFocus margin="dense" label="Username" type="text" fullWidth  value={username}  onChange={(e) => setUsername(e.target.value)} error={!!usernameError} helperText={usernameError}  required/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Email"  type="email"   fullWidth   value={email}  onChange={(e) => setEmail(e.target.value)}   error={!!emailError}   helperText={emailError} required   />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                    <Button onClick={() => setOpenDownloadDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDownloadSubmit} sx={{ backgroundColor: '#0C73EB', color: 'white', '&:hover': { backgroundColor: '#0C73EB',   } }}   >
                        Submit & Download
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbar.open} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <SnackbarContent
                    style={{
                        backgroundColor: snackbar.type === 'success' ? '#4caf50' : '#f44336',
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                    message={snackbar.message}
                />
            </Snackbar>
        </Fragment>
    );
}

export default TableDownloadButton;