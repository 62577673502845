import './App.css';
import SampleLevelCurationTable from "./pages/SampleTable";
import LoginForm from "./pages/LoginForm";
import { Navigate, Route, Routes } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import CurationRequest from "./pages/CreateCurationRequest";
import MetadataVisualization from "./pages/MetadataVisualization";
import StudyLevelCurationTable from "./pages/StudyTable";
import ExperimentLevelCurationTable from "./pages/ExperimentTable";
import SignupForm from "./pages/SignupForm";
import { useSelector } from "react-redux";
import { selectUser } from "./store/slices/userSlice";
import ChangePassword from "./pages/ChangePassword";
import SignupSuccess from "./pages/SignupSuccess";
import UserProfile from "./pages/UserProfile";
import ForgotPassword from "./pages/ForgotPassword";
import HomePage from "./pages/HomePage";
import MyCurationRequests from './pages/MyCurationRequests';
import PubliclyCuratedDatasets from './pages/PubliclyCuratedDatasets';
import StatementOfPurpose from './pages/StatementOfPurpose';
import PageNotFound from './pages/PageNotFound';

function CheckLogIn( { children } : any ) {
    const loggedUser = useSelector(selectUser);
    if (loggedUser.isAuthenticated) {
        return <Navigate to="/"/>;
    }
    if (loggedUser.email) {
        return <Navigate to="/change-password"/>;
    }
    return children;
}

function App() {
    return (
        <div className="app">
            <Routes>
                <Route path="/" element={ <RequireAuth><HomePage/></RequireAuth> }/>
                <Route path="/sample-table" element={<RequireAuth><SampleLevelCurationTable/></RequireAuth>}/>
                <Route path="/create-curation-request" element={<RequireAuth><CurationRequest/></RequireAuth>}/>
                <Route path="/metadata-visualization" element={<RequireAuth><MetadataVisualization/></RequireAuth>}/>
                <Route path="/login" element={ <CheckLogIn><LoginForm/></CheckLogIn> }/>
                <Route path="/study-table" element={ <RequireAuth><StudyLevelCurationTable/></RequireAuth> }/>
                <Route path="/experiment-table" element={ <RequireAuth><ExperimentLevelCurationTable/></RequireAuth> }/>
                <Route path="/signup" element={ <SignupForm/> }/>
                <Route path="/change-password" element={ <ChangePassword/> }/>
                <Route path="/signup-success" element={ <SignupSuccess/> } />
                <Route path="/user-profile" element={ <RequireAuth><UserProfile/></RequireAuth> }/>
                <Route path="/forgot-password" element={ <CheckLogIn><ForgotPassword/></CheckLogIn> }/>
                <Route path="/curation-requests" element={ <RequireAuth><MyCurationRequests/></RequireAuth> }/>
                <Route path="/publicly-curated-datasets" element={ <RequireAuth><PubliclyCuratedDatasets/></RequireAuth> }/>
                <Route path="/documentation" element={ <RequireAuth><StatementOfPurpose/></RequireAuth> }/>
                <Route path="*" element={ <PageNotFound/> }/>
            </Routes>
        </div>
    );
}

export default App;