import { Button, Checkbox, Divider, Menu, MenuItem } from "@mui/material";
import { ChangeEvent, Fragment, useState,useEffect } from "react";
import { GoChevronDown } from "react-icons/go";

interface Filter {
    Id: string;
    label: string;
    isVisible: boolean;
}
  
interface SelectedFilters {
    [key: string]: string[];
}

interface FilterListProps {
    filters: string[];
    filter: Filter;
    selectedFilters: SelectedFilters;
    updateSelectedFilters: (selectedFilters: SelectedFilters) => void;
    clearFilters: () => void;
    totalCounts: { [key: string]: number }; 
}

function FilterList({ filters, filter, selectedFilters, updateSelectedFilters, clearFilters,totalCounts }: FilterListProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const columnValues = filters&&filters.length > 0 ? filters : [];
    const [inputValue, setInputValue] = useState<string>('');
    const [showMessage, setShowMessage] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<string[]>(columnValues);
    const [checkedFilters, setCheckedFilters] = useState<string[]>([]);
    const [selectedFilterKey, setSelectedFilterKey] = useState<string>('');
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setInputValue('');
        setFilteredOptions(filters);
        setCheckedFilters(selectedFilters[filter.Id] || []);
        setSelectedFilterKey(filter.Id.toLowerCase());
    };

    const groups: string[][] = [];
    const columns = Math.ceil(filteredOptions.length / 9);
    for (let group = 0; group < filteredOptions.length; group += (columns > 4 ? 4 : columns)) {
        groups.push(filteredOptions.slice(group, group + (columns > 4 ? 4 : columns)));
    }
  
    const buttonStyles: React.CSSProperties = { marginLeft: '4px', textTransform: 'none', color: 'black', marginRight: '40px', display: 'inline-block', textAlign: 'left', padding: 0, width: 'auto' };
    const searchStyles: React.CSSProperties = { border: '1px solid lightgray', borderRadius: '5px', padding: '10px', fontSize: '15px', width: (columns === 2) ? '440px' : '385px', marginLeft: '5px', marginRight: '5px', marginBottom: '3px'};
    const menuItemStyles: React.CSSProperties = { paddingLeft: '8px', whiteSpace: 'normal', fontSize: '11pt' };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCheckedFilters(selectedFilters[filter.Id]);
        const value = event.target.value;
        setInputValue(value);
        const filtered = columnValues.filter((column) =>
            column.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };
    const handleFilterCheckboxChange = (value: string) => {
        if (value === "Select All") {
            if (showMessage && Array.isArray(columnValues)) {
                const allValues: string[] = Array.from(new Set(
                    columnValues.flatMap((item) => {
                        if (typeof item === 'object' && item !== null) {
                            return Object.values(item).filter((v): v is string => typeof v === 'string');
                        }
                        return [item];
                    })
                ));
               setCheckedFilters(checkedFilters.length < allValues.length ? allValues : []);
            } else {
                setCheckedFilters(checkedFilters.length < columnValues.length ? columnValues : []);
            }
            return;
        }
        const updatedFilters = checkedFilters.includes(value) ? checkedFilters.filter((filterValue) => { return filterValue !== value }) : [...checkedFilters, value];
        setCheckedFilters(updatedFilters);
    };

    
    const handleOkayButton = () => {{}
        if (selectedFilters[filter.Id] === checkedFilters) {
            setAnchorEl(null);
            return;
        }
        const updatedFilters = { ...selectedFilters, [filter.Id]: [...checkedFilters] };
        if(!Object.values(updatedFilters).some((value) => value.length > 0)) {
            clearFilters();
        }
        updateSelectedFilters(updatedFilters);
        setAnchorEl(null);
        setCheckedFilters([]);
    }
    let   totalCount=0;
    let shouldShowMessage = false;
    const renderedItems = groups.map((group, groupIndex) => {
        const isObjectArray = typeof group[0] === 'object' && group[0] !== null;
        const renderedValues = group.map((item, itemIndex) => {
            if (isObjectArray) {
                const keys = Object.keys(item).filter(k => k !== 'Count');
                if (keys.length === 1) { 
                    const key = keys[0];
                    const value = String((item as any)[key]);
                    totalCount = totalCounts[key] !== undefined ? totalCounts[key] : 0;
                    const count = (item as any)['Count'];
                    shouldShowMessage = true;
                    return (
                        <span key={`${key}-${itemIndex}`} style={{ display: 'flex' }}>
                            <Checkbox  size="small" style={{ cursor: 'pointer', transform: 'scale(0.85)', padding: 0, color: checkedFilters.includes(value as string) ? "#0C73EB" : "gray",}} checked={checkedFilters.includes(value as string)}onChange={() => handleFilterCheckboxChange(value as string)} />
                            <MenuItem onClick={() => handleFilterCheckboxChange(value as string)}  style={{ ...menuItemStyles, width: columns === 1 ? '385px' : '210px' }}  >  {`${value} ${count !== undefined ? `(${count})` : ''}`}
                            </MenuItem>
                        </span>
                    );
                }
            } else {
                return (
                    <span key={itemIndex} style={{ display: 'flex' }}>
                        <Checkbox size="small"style={{cursor: 'pointer', transform: 'scale(0.85)', padding: 0,  color: checkedFilters.includes(item as string) ? "#0C73EB" : "gray", }} checked={checkedFilters.includes(item as string)}  onChange={() => handleFilterCheckboxChange(item as string)} />
                        <MenuItem onClick={() => handleFilterCheckboxChange(item as string)} style={{ ...menuItemStyles, width: columns === 1 ? '385px' : '210px' }}>
                            {item}
                        </MenuItem>
                    </span>
                );
            }
            return null;
        });
    
        return (
            <div key={groupIndex} style={{ display: 'flex', marginLeft: '10px' }}>
                {renderedValues}
            </div>
        );
        
    });
    useEffect(() => {
        setShowMessage(shouldShowMessage);
    }, [shouldShowMessage]);
    return (
        <Fragment>
            <Button id="tablecolumn" aria-controls={open ? 'true' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} style={buttonStyles} onClick={handleClick}>
                {!open ? <span className="navbar">{filter.label}<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }} size={16} /></span>
                    : <span style={{ color: '#0C73EB' }}>{filter.label}<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }} size={16} /></span>}
            </Button>
            <Menu id='menuId' aria-labelledby='tablecolumn' anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} style={{ maxHeight: 700 }}>
               
            <div style={{ zIndex: 1, position: 'sticky', top: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', overflowY: 'hidden', padding: '8px' }}>
            <input onChange={handleInputChange} type="text" placeholder="Search" style={searchStyles} value={inputValue} />
              {totalCount > 0 && (
            <div style={{ fontWeight: 'bold' }}>
                Total number of {selectedFilterKey} counts: {totalCount}
            </div>
               )}
               </div>
                <div style={{ height: '100%', overflowY: 'hidden' }}>
                    {renderedItems.length === 0 ? <MenuItem style={menuItemStyles}>No matches found</MenuItem> : 
                    <Fragment>
                        {inputValue === '' && <div style={{ display: 'flex', marginLeft: '10px', paddingLeft: (columns === 2) ? '355px' : (columns === 1) ? '300px' : (columns === 3) ? '600px' : '800px' }}>
                            <Checkbox size="small" style={{ cursor: 'pointer', transform: "scale(0.85)", padding: 0, color: checkedFilters.length === columnValues.length ? "#0C73EB" : "gray" }} checked={checkedFilters.length === columnValues.length} onChange={() => handleFilterCheckboxChange("Select All")}/>
                            <MenuItem onClick={() => handleFilterCheckboxChange("Select All")} style={{ paddingLeft: '5px', width: '85px', whiteSpace: 'normal', fontSize: '11pt' }}>Select All</MenuItem>
                        </div>}
                        <Divider/>
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>{renderedItems}</div>
                    <Divider/>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
              { showMessage &&(
        <div style={{ marginRight: '7px', display: 'flex', alignItems: 'center' }}>
            <span style={{ color: 'red', marginRight: '5px' }}>*</span>
            The number inside the brackets specifies the sample count
        </div>
    )}
    <Button onClick={handleOkayButton} size="small" variant="contained" style={{ backgroundColor: '#0C73EB', color: 'white', textTransform: 'none', height: '30px', marginLeft: 'auto', marginRight: '5px', marginTop: '8px' }}>
        OK
    </Button>
             </div>
         </Fragment>}
         </div>
            </Menu>
        </Fragment>
    );
  }

  export default FilterList;